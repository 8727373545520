import './App.css';
import logo from './logo.jpg'

function App() {
    return (
        <>
            <header></header>
            <main>
                <img className="logo" src={logo} alt="Kerbborsche 2014 logo" />
            </main>
        </>
);
}

export default App;
